import { Injectable } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";

@Injectable({providedIn: 'root'})
export class NotificationService {

    constructor(private messageService: NzMessageService) {
    }

    success(message: string, title = ''): void {
        this.messageService.success(message);
    }

    info(message: string, title = ''): void {
        this.messageService.info(message);
    }

    warning(message: string, title = '') {
        this.messageService.warning(message);
    }

    error(message: any, title = '') {
        this.messageService.error(message);
    }
}