import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { NgClass, NgForOf, NgIf } from "@angular/common";

type PageDef = {number: number, label: number | string};

@Component({
    selector: 'app-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['pagination.component.scss'],
    imports: [
        NgForOf,
        NgClass,
        NgIf
    ],
    standalone: true
})

export class PaginationComponent implements OnInit {

    @Input() currentPage = 1;
    @Input() totalItems = 0;
    @Input() pageSize = 10;
    @Input() buttonsCount = 10;
    @Input() size = 'normal';
    @Input() labelMain = 'Pagination';
    @Input() labelNext = 'Next';
    @Input() labelPrev = 'Prev';
    @Input() selectPagesShow = true;

    @Output() change = new EventEmitter<number>();

    hasNext = false;
    hasPrev = false;
    currentPages: {start: number, end: number, pages: PageDef[]} = {
        start: 0,
        end: 0,
        pages: []
    };
    pagesForGoDropDown: any[] = [];
    oldPageSize: number = 10;

    constructor(public router: Router, public route: ActivatedRoute) {}

    ngOnInit() {
        this.getAllPages();
    }

    getPages() {
        let buttonsCount = this.buttonsCount;
        let pageCount = this.getPageCount();
        this.hasNext = pageCount > this.currentPage;
        // This is for optimizing the update for go button ...
        // If we update it frequently the UI becomes buggy ... Go button flickers ...
        if (this.pageSize !== this.oldPageSize) {
            let pages = [];
            for (let i = 1; i <= pageCount; ++i) {
                pages.push({ number: i, label: i });
            }
            this.pagesForGoDropDown = pages;
            this.oldPageSize = this.pageSize;
        }

        let currentPage = this.currentPage;

        let start = +(buttonsCount / 2);
        start = Math.round(Math.max(1, currentPage - start));

        let end = start + buttonsCount - 1;
        if (end >= pageCount) {
            end = pageCount;
            start = Math.max(1, end - buttonsCount + 1);
        }

        if (start === this.currentPages.start && end === this.currentPages.end) {
            return this.currentPages.pages;
        }

        this.currentPages.start = start;
        this.currentPages.end = end;
        this.currentPages.pages = [];

        for (let i = start; i <= end; ++i) {
            this.currentPages.pages.push({number: i, label: i});
        }

        return this.currentPages.pages;
    }

    getAllPages() {
        let pageCount = this.getPageCount();
        let pages = [];
        for (let i = 1; i <= pageCount; ++i) {
            pages.push({number: i, label: i});
        }
        this.pagesForGoDropDown = pages;
        this.oldPageSize = this.pageSize;
        if (pageCount > this.buttonsCount) {
            this.hasNext = true;
        }
        return pages;
    }

    getPageCount() {
        let count = Math.floor(this.totalItems / this.pageSize);
        if (this.totalItems % this.pageSize !== 0) {
            count++;
        }
        return count;
    }

    onChangePage(number) {
        let pageCount = this.getPageCount();
        this.hasPrev = number > 1;
        this.hasNext = number < pageCount;
    }

    setPage(number) {
        if (this.currentPage === number) {
            return;
        }
        this.currentPage = number;
        this.onChangePage(number);
        this.change.emit(number);
    }

    nextPage() {
        this.setPage(this.currentPage + 1);
    }

    prevPage() {
        this.setPage(this.currentPage - 1);
    }

    onChangeSelectPage(e) {
        e.preventDefault();
        e.stopPropagation();
        let page = parseInt(e.target.value);
        if (isNaN(page)) {
            return false;
        }
        if (page >= 0) {
            this.setPage(page);
        }
        return false;
    }
}
