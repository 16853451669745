<div class="pagination-component">
    <div *ngIf="selectPagesShow && getPageCount() > buttonsCount" class="select-page">
        <span>
            Navigate to
        </span>
        <select class="form-control" (change)="onChangeSelectPage($event)">
            <option value="" selected>Go</option>
            <option *ngFor="let page of pagesForGoDropDown" [value]="page.number">{{ page.label }}</option>
        </select>
    </div>
    
    <ul class="pagination" [ngClass]="{'pagination-lg': size === 'lg', 'pagination-sm': size === 'sm'}">
        <li [ngClass]="{'disabled': !hasPrev}">
            <a (click)="hasPrev && prevPage()" [attr.aria-label]="labelPrev">
                <span [attr.aria-hidden]="true" class="fas fa-backward"></span>
            </a>
        </li>
        <li *ngFor="let page of getPages()" [ngClass]="{active: page.number == currentPage}">
            <a (click)="setPage(page.number)">{{ page.label }}</a>
        </li>
        <li [ngClass]="{'disabled': !hasNext}">
            <a (click)="hasNext && nextPage()" [attr.aria-label]="labelNext">
                <span [attr.aria-hidden]="true" class="fas fa-forward"></span>
            </a>
        </li>
    </ul>
</div>